import React, { useState, useEffect } from "react";
import "src/scss/main.css";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineLock, AiOutlineEye } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import Page from "../../../component/Page";
import { BASE_URL } from "../../../config/APICongig";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import {
  useHistory,
  Link as RouterComponent,
  useLocation,
} from "react-router-dom";
import TopBar from "src/layouts/LoginLayout/index.js";
import * as yep from "yup";
import {
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  IconButton,
  FormControl,
  makeStyles,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import axios from "axios";

import CustomToast from "../../../component/customToast";

const useStyles = makeStyles((theme) => ({
  buttonbox: {
    padding: "10px 18px",
    width: "100%",
    height: "37.4px",
    background:
      "linear-gradient(rgb(187, 129, 233) 0%, rgb(123, 11, 211) 100%)",
    borderRadius: "7px",

    [theme.breakpoints.only("sm")]: {
      maxWidth: "112px",
      fontSize: "9px",
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: "112px",
      fontSize: "9px",
    },
  },
  loginform: {
    padding: "24px 19px ",
  },

  signupbox: {
    padding: " 29px 19px",
  },
  textFiled: {
    border: "1px solid rgba(74, 74, 74, 0.5)",

    borderRadius: "7px",
  },
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#343434",
    paddingBottom: "8px",
  },
  logosec: {
    textAlign: "center",
    paddingTop: "40px",
    pšddingBottom: "16px",
    "@media(min-width:1280px)": {
      // display: "none",
    },
  },
}));
function Reset(props) {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const otp = location.state?.otp;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [open, setOpen] = useState(false);

  const [isLoading, setLoader] = useState(false);

  let formData = new FormData();


  const handleCloses = (x) => {
    setOpen(x);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetPaswordHandler = async (values) => {

    formData.append("password", values.password)
    formData.append("confirmPassword", values.confirmPassword)
   
    try {

      setLoader(true);
      const res = await axios({
        method: "POST",
        url: `${BASE_URL}/admin/resetPassword`,
        headers: {
          token: localStorage.getItem("token"),
        },
        data: { password: values.password, confirmPassword: values.confirmPassword },
      });
   
      if (res.data.responseCode === 200) {
        setLoader(false);
        toast.success(res.data.responseMessage);
        history.push("/");
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.responseMessage);
     
    }
  };

  return (
    <>
      {/* <CustomToast type={type} handleClose={handleCloses} open={open} message={message}/> */}

      <Page title="Reset-password">
        <TopBar>
          <Grid>
            <Box className={classes.logosec}>
              <img
                style={{ cursor: "pointer", width: "103px" }}
                onClick={() => history.push("/")}
                src="images/Reh-logo.png"
              />


            </Box>
            <Box>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "34px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  color: "#000",
                  lineHeight: "23px",
                }}
              >
                RESET PASSWORD
              </Typography>
              <Box className="signupbox">
                <Grid container direction={"column"}>
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "center" }}
                  ></Grid>

                  <Formik
                    onSubmit={(values) => resetPaswordHandler(values)}
                    // onSubmit={() => history.push("/")}
                    initialValues={{
                      password: "",
                      confirmPassword: "",
                    }}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={yep.object().shape({
                      password: yep
                        .string()
                        .max(16, "16 charactors are allowed.")
                        .matches(
                          /^(?=.*[A-Z][a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
                          "Password must be 8 to 16 character long with one uppercase, one lower case, a number, and a special character. "
                        )
                        .required("Password is required."),
                      confirmPassword: yep
                        .string()
                        .required("Confirm password is required")
                        .oneOf(
                          [yep.ref("password"), null],
                          "Confirm password should match with the new password"
                        ),
                    })}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form style={{ padding: "28px 34px " }}>
                        <Grid item>
                          <Box>
                            <label className={classes.title}>
                              New Password
                            </label>
                            <TextField
                              style={{ marginTop: "3%" }}
                              className={classes.textFiled}
                              placeholder="New password"
                              size="small"
                              variant="outlined"
                              fullWidth
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              name="password"
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{
                                maxLength: 16,
                              }}
                              InputProps={{
                                className: classes.textfiled,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      <Box className={classes.passsec}>
                                        {showPassword ? (
                                          <AiOutlineEye
                                            style={{
                                              color: "#7A7A7A",
                                              // color: "#7A7A7A",
                                              fontSize: "18px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          />
                                        ) : (
                                          <AiOutlineEyeInvisible
                                            style={{
                                              color: "#7A7A7A",
                                              // color: "#7A7A7A",
                                              fontSize: "18px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Box
                                      style={{
                                        borderRight: "0.5px solid #7A7A7A",
                                      }}
                                    >
                                      <AiOutlineLock
                                        style={{
                                          marginLeft: "-2px",
                                          marginRight: "10px",
                                          width: "14px",
                                          color: "#6A36FF ",
                                          fontSize: "22px",
                                        }}
                                      />
                                    </Box>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText error style={{ fontSize: "12px" }}>
                              {touched.password && errors.password}
                            </FormHelperText>
                          </Box>
                        </Grid>
                        &nbsp;
                        <Grid
                          item
                          style={{ marginBottom: "10px", marginTop: "-0px" }}
                        >
                          <FormControl fullWidth>
                            <Box
                              style={{ width: "100%" }}
                              className={classes.loginForm1}
                            >
                              <Typography className={classes.title}>
                                Confirm Password
                              </Typography>
                              <TextField
                                className={classes.textFiled}
                                placeholder="Enter your Confirm password"
                                size="small"
                                variant="outlined"
                                fullWidth
                                inputProps={{ maxLength: 16 }}
                                value={values.confirmPassword}
                                type={showPassword1 ? "text" : "password"}
                                name="confirmPassword"
                                // placeholder="Confirm your password"
                                error={Boolean(
                                  touched.confirmPassword &&
                                  errors.confirmPassword
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  className: classes.textfiled,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setShowPassword1(!showPassword1)
                                        }
                                        edge="end"
                                      >
                                        <Box className={classes.passsec}>
                                          {showPassword1 ? (
                                            <AiOutlineEye
                                              style={{
                                                color: "#7A7A7A",
                                                // color: "#7A7A7A",
                                                fontSize: "18px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            />
                                          ) : (
                                            <AiOutlineEyeInvisible
                                              style={{
                                                color: "#7A7A7A",
                                                // color: "#7A7A7A",
                                                fontSize: "18px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        style={{
                                          borderRight: "0.5px solid #7A7A7A",
                                        }}
                                      >
                                        <AiOutlineLock
                                          style={{
                                            marginLeft: "-2px",
                                            marginRight: "10px",
                                            width: "14px",
                                            color: "#6A36FF ",
                                            fontSize: "22px",
                                          }}
                                        />
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <FormHelperText
                                error
                                style={{ fontSize: "12px", marginLeft: "1%" }}
                              >
                                {touched.confirmPassword &&
                                  errors.confirmPassword}
                              </FormHelperText>
                            </Box>
                          </FormControl>
                        </Grid>
                        <Box>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonbox}
                            type="submit"
                            disabled={isLoading}
                          >
                            Confirm {isLoading && <ButtonCircularProgress />}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </TopBar>
      </Page>
    </>
  );
}

export default Reset;
