import axios from "axios";




export const getProfileData = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        // url: `${BASE_URL}/admin/viewUser?_id=${id}`,
      });
      return res;
    } catch (error) {
      console.log("Error", error);
    }
  };



