import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout/index";
import DashboardLayout from "src/layouts/DashboardLayout1";
import ForgetasswordLink from "src/views/Auth/forget-password-link";
import Login from "src/views/Auth/forget-password-link/OtpVerify";
import Reset from "src/views/Auth/reset-password/index.js";
import PasswordLayout from "./layouts/PasswordLayout/index";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },

  {
    exact: true,
    guard: true,
    path: "/dash",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),
  },
  {
    exact: true,
    guard: true,
    path: "/userManagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/walletDetail")),
  },
  {
    exact: true,
   // guard: true,
    path: "/reset",
    layout: Reset,
    component: lazy(() => import("src/views/Auth/reset-password/index.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),
  },
  {
    exact: true,
    //guard: true,
    path: "/change-email-password",
    layout: PasswordLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/changeEmailPassword.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/hot-wallet-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/hotWalletManagement/hotWallet.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/price-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/priceManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/transfer-approval",
    //layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/hotWalletManagement/tranferApproval.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-content-terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/hotWalletManagement/viewHotWallet.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-Profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/viewProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/profileEdit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/profileEdit")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-hot-wallet-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/hotWalletManagement/viewHotWallet.js")),
  },
  // OLD BONUS MANAGEMENT SCREEN
  // {
  //   exact: true,
  //   path: "/bonus-management",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/bonusManagement/index")),
  // },
  {
    exact: true,
    guard: true,
    path: "/bonus-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/bonusManagement/newindex")),
  },
  {
    exact: true,
    guard: true,
    path: "/coupon-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/couponManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/coupan-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/couponManagement/couponDetail")),
  },
  {
    exact: true,
    guard: true,
    path: "/bonus-table",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/bonusManagement/bonusMangement")),
  },
  {
    exact: true,
    guard: true,
    path: "/bonus-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/bonusManagement/bonusDetails.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/notification.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/notificationDetails.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/editNotification.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/addNotification")),
  },
  {
    exact: true,
    guard: true,
    path: "/static-content",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/staticContentManagement/viewContent")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-static-content",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/staticContentManagement/viewDetailedContent")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-static-content",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/staticContentManagement/editContent")),
  },
  {
    exact: true,
    guard: true,
    path: "/contact-support",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/contactSupportManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/contact-us-management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/contactSupportManagement/contactUsListing")),
  },
  {
    exact: true,
    guard: true,
    path: "/contact-details",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/contactSupportManagement/contactUsDetail")),
  },
  {
    exact: true,
    guard: true,
    path: "/Subscribe-Management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/contactSupportManagement/subscribeListing")),
  },
  {
    exact: true,
    guard: true,
    path: "/Subscriber-details",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/contactSupportManagement/subscriberDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/AddFaq")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/EditFaq")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/ViewFaq")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-logs-management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/userLogsManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/admin-logs-management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/adminLogsManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/settings",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/settings/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/update-contact",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/settings/updateContact.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/settings/editProfile.js")),
  },


  {
    exact: true,
    guard: true,
    path: "/transacation",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/transactionHistory/transactionHistory")),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transactionHistory/transactionDetails.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/wallet-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/WalletManagement/walletManage")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/WalletManagement/viewWallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/tradedetails",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/tradeDetails")),
  },

  {
    exact: true,
    //guard: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
   // guard: true,
    path: "/verify-otp",
    layout: Login,
    component: lazy(() => import("src/views/Auth/forget-password-link/OtpVerify")),
  },

  {
    exact: true,
    guard: true,
    path: "/settlement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/settlement")),
  },
  // {
  //   exact: true,
  //   path: "/tokenomics-management",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/tokenomics/index")),
  // },
  {
    exact: true,
    guard: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
