/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { FaSignOutAlt, FaUserCircle, FaUser, FaRegEdit } from "react-icons/fa";
import { AiFillHome, AiOutlineDashboard } from "react-icons/ai";
import { FiPaperclip } from "react-icons/fi";
import { RiBarChartFill } from "react-icons/ri";
import { GiBackwardTime } from "react-icons/gi";
import { AiFillWallet } from "react-icons/ai";
import { CgTranscript } from "react-icons/cg";
import ConfirmationDialog from "src/component/Confirmation";
import Cookies from 'js-cookie';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  
  Slide,
  
} from "@material-ui/core";
import NavItem from "./NavItem";


const sections = [
  // {
  //   items: [
  //     {
  //       title: "Home",
  //       icon: "/images/home.svg",
  //       active: "/images/home.svg",
  //       href: "/",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Dashboard",
        icon: "/images/dashActive.svg",
        active: "/images/dashActive.svg",
        href: "/dash",
      },
    ],
  },
  {
    items: [
      {
        title: "User Management",
        icon: "/images/user.svg",
        active: "/images/user.svg",
        href: "/userManagement",
      },
    ],
  },
  {
    items: [
      {
        title: "Wallet Management",
        icon: "/images/trans.svg",
        active: "/images/trans.svg",
        href: "/wallet-management",
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Bonus Management",
  //       icon: "/images/bonus.svg",
  //       active: "/images/bonus.svg",
  //       href: "/bonus-management",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Hot Wallet Management",
        icon: "/images/award.svg",
        active: "/images/award.svg",
        href: "/hot-wallet-management",
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Coupon Management",
  //       icon: "/images/dashActive.svg",
  //       active: "/images/dashActive.svg",
  //       href: "/coupon-management",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Static Content Management",
        icon: "/images/static.svg",
        active: "/images/static.svg",
        href: "/static-content",
      },
    ],
  },
  {
    items: [
      {
        title: "Support Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/contact-support",
      },
    ],
  },
  {
    items: [
      {
        title: "FAQ Management",
        icon: "/images/feedback.svg",
        active: "/images/feedback.svg",
        href: "/faq",
      },
    ],
  },
  {
    items: [
      {
        title: "Logs Management",
        icon: "/images/feedback.svg",
        active: "/images/feedback.svg",
        href: "/user-logs-management",
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Tokenomics Management",
  //       icon: "/images/feedback.svg",
  //       active: "/images/feedback.svg",
  //       href: "/tokenomics-management",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Price Management",
        icon: "/images/feedback.svg",
        active: "/images/feedback.svg",
        href: "/price-management",
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Admin Logs Management",
  //       icon: "/images/feedback.svg",
  //        active: "/images/feedback.svg",
  //       href: "/admin-logs-management",
  //     },
  //   ],
  // },
  // {
  //   items: [
  //     {
  //       title: "Settings",
  //       icon: "/images/feedback.svg",
  //        active: "/images/feedback.svg",
  //       href: "/settings",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Change Password",
        icon: "/images/feedback.svg",
        active: "/images/feedback.svg",
        href: "/change-password",
      },
    ],
  },
  {
    items: [
      {
        title: "Logout",
        icon: "/images/log-out.svg",
        active: "/images/log-out.svg",
        href: "/",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: true,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: "rgb(11, 52, 131)",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 280,
    top: 0,
    height: "100%",
    background: "#FBFBFB",
    backgroundSize: "cover",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#fff",

    "&:hover": {
      color: "#fff",
      background: "rgb(90 134 255) !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  // buttonLeaf: {
  //   color: "#263944 !important",
  //   padding: "11px 8px",
  //   justifyContent: "flex-start",
  //   textTransform: "none",
  //   letterSpacing: 0,
  //   borderLeft: "solid 8px transparent",
  //   borderRadius: 0,
  //   fontSize: "13px",
  //   borderLeft: "3px solid transparent",
  //   "&:hover": {
  //     background: "rgb(90 134 255)",
  //     color: "#FFFFFF",
  //   },

  //   "&.depth-0": {
  //     "& $title": {
  //       fontWeight: theme.typography.fontWeightMedium,
  //       color: "#263944 !important"
  //     },
  //   },
  // },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#263944",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = useState(false);
  const confirmationHandler = () => {
    console.log("fffffff")
    Cookies.remove('auth')
    history.push("/login");
  };



  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="example"
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mt={2}
          style={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
          }}
        >
          <Box>
            <Link to="/dash">
              <img src="images/Reh-logo.png" style={{ width: "100px", height:"100px" }} />
            </Link>
          </Box>
          &nbsp;&nbsp;&nbsp;
        </Box>
        &nbsp;
        <Box pt={1}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          // className="drawerimg"
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
