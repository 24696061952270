import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import styled from "styled-components";

const DivBack = styled.div`
  background: linear-gradient(
    180deg,
    rgba(182, 113, 236, 0.8) 0%,
    rgba(44, 0, 169, 0.8) 100%
  ); ;
`;
const width = window.innerWidth;
const height = window.innerHeight;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        minHeight: "110vh",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingTop: 70,
        // maxHeight: height,
        [theme.breakpoints.up("lg")]: {
            // paddingLeft: 250,
            paddingLeft: 260,
            paddingRight: " 30px",
        },
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingBottom: "5rem",
        background: theme.palette.primary.paper,
    },
    content: {
        // background: "#263944",
        flex: "1 1 auto",
        height: "100%",
        width: "100%",
        position: "relative",
        WebkitOverflowScrolling: "touch",
    },
}));

const PasswordLayout = ({ children }) => {
    const classes = useStyles();
    

    return (
        <div className={classes.root}>
            
           
               
                    <div className={classes.content} id="main-scroll">
                        {children}
                    </div>
               
           
        </div>
    );
};

PasswordLayout.propTypes = {
    children: PropTypes.node,
};

export default PasswordLayout;
