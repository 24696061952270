import React, { useState, useEffect, useContext } from "react";
import "src/scss/main.css";
import { Box, Typography, Grid, makeStyles, Button } from "@material-ui/core";
import styled from "styled-components";
import OtpInput from "react-otp-input";

import { useHistory, Link as RouterComponent } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { calculateTimeLeft } from "./timer";
import TopBar from "src/layouts/LoginLayout/index.js";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../config/APICongig";
import Page from "src/component/Page";
import axios from "axios";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  textfiled: {
    width: "10px",
    height: "40px",
    background: "rgba(217, 217, 217, 0.1)",
    border: "1px solid #D4D4D4",
    borderRadius: "3px",
  },
  submit: {
    height: "41.73px",
    background:
      "linear-gradient(rgb(187, 129, 233) 0%, rgb(123, 11, 211) 100%)",

    borderRadius: "7px",
  },
  forgot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  OtpDiv: {
    marginTop: "2rem",
    paddingBottom: "3rem",
    paddingLeft: "15px",
  },

  inputBlocks: {
    paddingLeft: "13px",
    marginTop: "14px",
  },
  inputFields: {},
  error: {
    margin: "10px 0px 0px 71px",
    letterSpacing: "0.2px",
    fontFamily: "Inter",
    fontStyle: " normal",
    fontWeight: "700",
    fontSize: "10px",
    lineHeight: "12px",
    color: " #E03128",
  },
  Link: {
    textDecoration: "none",
    fontSize: "18px",
    float: "right",
    padding: "16px 40px 0 0px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 700",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.06em",
    color: "#FF0000",
  },
  timer: {
    marginRight: "25px",
    paddingLeft: "11.5rem",
  },
  TimerExpired: {
    color: "#E03128",
    marginRight: "25px",
    paddingLeft: "11.5rem",
  },
  timerGrid: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "right",
  },
  title: {
    fontWeight: "500",
    textAlign: "center",
    fontSize: "34px",
    fontFamily: "Inter",
    marginTop: "20px",
    color: "#000000",
    "@media (max-width:767px)": {
      fontSize: "27px",
    },
  },
  title2: {
    fontWeight: "400",
    fontSize: "17px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "#000000",
    lineHeight: "17px",
    textAlign: "center",
    marginBottom: "25px",
  },
  logosec: {
    textAlign: "center",

    "@media(min-width:1280px)": {
      // display: "none",
    },
  },
}));
const FieldInput = styled.input`
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.1) !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 3px !important;
  border: 1px solid #333;
  text-align: center;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function Login(props) {
  const history = useHistory();
  var auth = useContext(AuthContext);
 
  const classes = useStyles();
  const [timer, setTimer] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const handleChange = (code) => {
    setCode(code);
    if (code.length == 4) {
      setError("");
    }
  };
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const handleClose = (x) => {
    setOpen(x);
  };

  // ================= API Integration ===============

  const handleFormSubmit = async (values) => {
    const userEmail = localStorage.getItem("emailForgot");
    setLoader(true);
    if (code.length == 4) {
      try {
        const res = await axios({
          method: "POST",
          url: `${BASE_URL}/admin/verifyOTP`,

          data: {
            email: userEmail,
            otp: values,
          },
        });

    

        if (res.data.responseCode === 200) {
          setLoader(false);
          toast.success(res.data.responseMessage);
          localStorage.setItem("token", res.data.result.token)
          history.push("/reset");
        } else {
          history.push("/verify-otp");
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        setLoader(false);

        if (error.response.data.responseCode === 400) {
          setLoader(false);

          toast.error(error.response.data.responseMessage);
        }
      }
    } else {
      // setError("Invalid OTP");
      toast.error("Invalid OTP");
    }
  };

  const handleResendOTP = async (values) => {

    // setLoader(true);
    try {
      const res = await axios.put(`${BASE_URL}/admin/resendOTP`, {
        email: localStorage.getItem("emailForgot"),
      });
     
      if (res.data.responseCode === 200) {
        setLoader(false);
        toast.success(res.data.responseMessage);
        // setTimer(true);
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        // setIsLoading(false);
      }

      return res;
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  return (
    <>
      {calculateTimeLeft}

      {calculateTimeLeft}

      <TopBar>
        <Page title="Verify OTP">
          <Grid className="d-flex height100" style={{ padding: "27px" }}>
            <Box>
              <Box className="signupbox">
                <Grid container direction={"column"}>
                  <Grid item>
                    <Box className={classes.logosec}>
                      <img
                        style={{ cursor: "pointer", width: "103px" }}
                        onClick={() => history.push("/")}
                        src="images/Reh-logo.png"
                      />

                      {/* <Logo width="180" style={{ cursor: "pointer" }} /> */}
                    </Box>
                  </Grid>
                  <Grid item style={{}}>
                    <Typography className={classes.title}>
                      VERIFICATION CODE
                    </Typography>
                    <Typography className={classes.title2}>
                      A 4- digit OTP has been sent to your registered e-mail.
                    </Typography>
                  </Grid>{" "}
                  <Box className={classes.inputBox}>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <OtpInput
                        value={code}
                        onChange={handleChange}
                        numInputs={4}
                        separator={<span style={{ width: "33px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          borderRadius: "8px",
                          width: "60px",
                          height: "60px",
                          fontSize: "20px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "#333",

                          background: "rgba(217, 217, 217, 0.1)",
                          border: "1px solid #333",
                          borderRadius: " 3px",
                        }}
                        focusStyle={{
                          border: "1px solid #6A36FF",
                          outline: "none",
                        }}
                      />
                    </Grid>
                  </Box>
                  <Typography className={classes.error}>{error}</Typography>
                  <Box>
                    {auth.timeLeft && auth.timeLeft.seconds >= 0 ? (
                      <>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography
                            variant="body1"
                            style={{
                              float: "right",
                              color: "#6A36FF",
                              margin: "28px 0 11px 0",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "15px",
                              lineHeight: "12px",
                              letterSpacing: "0.06em",
                              color: "#6A36FF",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {auth.timeLeft?.minutes} : {auth.timeLeft?.seconds}
                          </Typography>{" "}
                        </Box>
                      </>
                    ) : (
                      <Typography
                        variant="contained"
                        color="secondary"
                        style={{
                          float: "right",
                          color: "#6A36FF",
                          margin: "28px 0 11px 0",
                          fontFamily: "Inter",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "12px",
                          letterSpacing: "0.06em",
                          color: "#6A36FF",
                          cursor: "pointer",
                        }}
                        fullWidth
                        onClick={() => {
                          handleResendOTP();
                        }}
                        disabled={auth.timeLeft && auth.timeLeft.seconds > 0}
                      >
                        {" "}
                        Resend OTP{" "}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    type="submit"
                    disabled={isLoading}
                    onClick={() => handleFormSubmit(code)}
                  // onClick={() => history.push("/reset")}
                  >
                    Submit {isLoading && <ButtonCircularProgress />}
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Page>
      </TopBar>
    </>
  );
}

export default Login;
